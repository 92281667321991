//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportMixin from "@/mixins/Report";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import BrandProductsTable from "@/components/Brand/ProductsTable.vue";
import BrandSellersTable from "@/components/Brand/SellersTable.vue";
import BrandCategoriesTable from "@/components/Brand/CategoriesTable.vue";
import BrandSegmentsTable from "@/components/Brand/SegmentsTable.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
import ListAddItemButton from "@/components/List/AddItemButton.vue";
import SwitchInput from "@/components/SwitchInput.vue";
const tableComponents = {
  products: BrandProductsTable,
  sellers: BrandSellersTable,
  categories: BrandCategoriesTable,
  segments: BrandSegmentsTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    const name = this.brand.brand;
    return {
      title: name ? `${name} 品牌指标概述` : undefined
    };
  },
  data() {
    let cells = getEntityCellsConfig("brand");
    const customCells = {
      avgPerDay: {
        title: "每日平均<br>销售额",
        multi: [{
          id: "avg_sold",
          unit: cells.avg_sold.unit
        }, {
          id: "avg_revenue",
          unit: cells.avg_revenue.unit
        }],
        color: cells.avg_sold.color
      }
    };
    delete cells.avg_sold;
    delete cells.avg_revenue;
    cells = {
      ...cells,
      ...customCells
    };
    return {
      brand: null,
      tabs_tables: [{
        id: "products",
        title: "产品"
      }, {
        id: "sellers",
        title: "卖家"
      }, {
        id: "categories",
        title: "产品类别"
      }
      /* //&: 2022-02-14 : api_CH пока не реализован
              {
                  id: 'segments',
                  title: 'Ценовая 部分ация'
              },
              */],

      tabs_tables_model: null,
      cells
    };
  },
  methods: {
    getTableComponent(id) {
      return tableComponents[id];
    },
    getTableId() {
      return this._uid;
    },
    async loadBrand() {
      this.brand = await this.$store.dispatch("brand/getBrand", {
        ...this.reportSettings
      });
    },
    loadOverview() {
      return this.$store.dispatch("brand/brandOverviewAll", this.reportSettings);
    }
  },
  async mounted() {},
  computed: {
    track() {
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / 品牌分析 / ${this.tabs_tables_model.title}`,
        name: this.brand.brand,
        date: this.reportSettings.date,
        date2: this.reportSettings.date2
      };
    },
    fileName() {
      return `${this.$route.params.mp.toUpperCase()} 品牌 ${this.brand.brand} ${this.tabs_tables_model.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      return {
        mp: this.$route.params.mp,
        b_id: this.$route.params.b_id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs
      };
    },
    brandKey() {
      return `${this.$route.params.b_id}:${this.$route.params.mp}`;
    },
    tableKey() {
      return `${this.brandKey}:${this.tabs_tables_model.id}`;
    }
  },
  watch: {
    reportSettings: {
      handler(v) {
        if (!v) {
          return;
        }
        this.loadBrand();
      },
      immediate: true
    }
  },
  components: {
    MainTitle,
    AppTabs,
    DataTableActions,
    BrandProductsTable,
    EntityCharts,
    ReportPage,
    ListAddItemButton,
    SwitchInput
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("brand/getBrandSegments", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "部分",
        show: true,
        name: "segment",
        width: 170,
        filter: "select",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                b_id: this.reportSettings.b_id,
                tab: "products"
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs,
                filters: JSON.stringify({
                  price: [item.segment_start, item.segment_end]
                })
              }
            },
            text: item.segment,
            target: "_blank"
          };
        }
      }, {
        title: `${days} 天的收入`,
        show: true,
        name: "revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }, {
        title: `已售 ${days} 天(件)`,
        show: true,
        name: "sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        ////title: `每个产品的收入`,
        title: `平均账单`,
        show: true,
        name: "revenue_per_product",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `平均. 日销量(个)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 170,
        filter: "numberRange"
      }, {
        title: `平均. 每日收入`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 170,
        filter: "numberRange"
      }, {
        title: "产品",
        show: true,
        name: "products",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "有销售的产品",
        show: true,
        name: "products_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "卖家",
        show: true,
        name: "sellers",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "有销售的卖家",
        show: true,
        name: "sellers_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};
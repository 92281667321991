//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path";
import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable,
    CategoryPath
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("brand/getBrandCategories", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "类别",
        show: true,
        name: "c_name_path",
        width: 440,
        filter: "text",
        type: "slot"
      }, {
        title: "产品",
        show: true,
        name: "products",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "有销售的产品",
        show: true,
        name: "products_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "卖家",
        show: true,
        name: "sellers",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "有销售的卖家",
        show: true,
        name: "sellers_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        ////title: `每个产品的收入`,
        title: `平均账单`,
        show: true,
        name: "revenue_per_product",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `已售 ${days} 天(件)`,
        show: true,
        name: "sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `${days} 天的收入`,
        show: true,
        name: "revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `平均. 日销量(个)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `平均. 每日收入`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};